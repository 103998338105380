import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import {
  Cookie,
  Cookies,
  postBackupFactorv2,
  trackOtpEnterCodeView,
} from "../utils";
import { OtpInput } from "@ig-phoenix/otp-input";
import { useTranslation } from "react-i18next";
import {
  OTPBackupFactorCounter,
  OTPDescription,
  OtpInputWrapper,
  StyledButton,
  StyledContainer,
  StyledErrorMessage,
} from "../components";
import { UseMutateFunction } from "@tanstack/react-query/build/lib/types";
import { OTPFooter } from "../components/OTPFooter";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

type EnterCodeProps = {
  availableFactors: FactorData[];
  mutate: UseMutateFunction<
    SuccessSessionResponse,
    Error,
    OTPMutationVariables,
    unknown
  >;
  isError: boolean;
  reset: () => void;
};

type Props = {
  marginBottom?: string;
  justifyContent?: string;
  alignItems?: string;
};

const Wrapper = styled.div<Props>`
  margin-top: 0px;
  align-items: ${(props) => props.alignItems};
  margin-bottom: ${(props) => props.marginBottom};
  display: inline-flex;
  width: 100%;
  justify-content: ${(props) => props.justifyContent};
`;

export const MFAEnterCodeV2: FC<EnterCodeProps> = ({
  availableFactors,
  mutate,
  isError,
  reset,
}) => {
  const [code, setCode] = useState<string>("");
  const [descriptionContext, setDescriptionContext] = useState("");
  const [factor, setFactor] = useState<FactorType>("token");
  const [stateToken, setStateToken] = useState<string>(
    Cookie.get(Cookies.STATE_TOKEN) || ""
  );
  const [shouldShowIncompleteCodeError, setShouldShowIncompleteCodeError] =
    useState(false);
  const { mutate: callBackupFactor } = useMutation(
    ({ factor }: { factor: FactorType }) => {
      if (factor === "token") {
        return Promise.resolve({
          stateToken: Cookie.get(Cookies.STATE_TOKEN),
          profile: {
            email: "",
          },
        } as BackupFactorResponse);
      }

      if (!!availableFactors) {
        return postBackupFactorv2(
          availableFactors.find((f) => f.factorType === factor).factorId
        );
      }
    },
    {
      onSuccess: (data, { factor }) => {
        setFactor(factor);
        resetCode();
        setStateToken(data.stateToken);
        if (data.profile["@type"] === "sms") {
          setDescriptionContext(data.profile.phoneNumber);
        } else if (data.profile["@type"] === "email") {
          setDescriptionContext(data.profile.email);
        }
      },
    }
  );

  const { t } = useTranslation();
  const otpFilled = code?.length === 6;

  let errorMessage;

  if (shouldShowIncompleteCodeError) {
    errorMessage = !code
      ? "loginForm.login.error.missingCode"
      : "loginForm.login.error.invalidCode";
  } else if (isError) {
    errorMessage = "loginForm.login.error.invalidCode";
  }

  const onCodeChangeHandler = (code: string) => {
    setShouldShowIncompleteCodeError(false);
    reset();
    setCode(code);
  };

  const resetCode = () => {
    setShouldShowIncompleteCodeError(false);
    reset();
    setCode("");
  };

  const onClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();
    setShouldShowIncompleteCodeError(!otpFilled);

    if (otpFilled) {
      mutate({
        token: code,
        stateToken: stateToken,
        factor,
      });
    }
  };

  useEffect(() => {
    trackOtpEnterCodeView();
  }, []);

  useEffect(() => {
    setStateToken(stateToken || Cookie.get(Cookies.STATE_TOKEN) || "");
  }, [factor]);

  return (
    <StyledContainer>
      <OTPDescription factor={factor} context={descriptionContext} />
      {factor !== "token" && (
        <OTPBackupFactorCounter
          factor={factor}
          fetchFactor={callBackupFactor}
        />
      )}
      <OtpInputWrapper>
        <OtpInput
          onChange={onCodeChangeHandler}
          value={code}
          errorMessageId="otp-error-message"
          invalid={!!errorMessage}
        />
        {errorMessage && (
          <StyledErrorMessage id="otp-error-message">
            {t(errorMessage)}
          </StyledErrorMessage>
        )}
      </OtpInputWrapper>
      {/*TODO: Unblock and implement once remember is implemented
      <Wrapper
        justifyContent="space-between"
        marginBottom="8px"
        alignItems="center"
      >
        <Header variant="h2" size="medium">
          {t("loginForm.rememberMe.confirm")}
        </Header>
        <Toggle checked={false} onClick={() => {}} />
      </Wrapper>
      <Wrapper marginBottom="40px">
        <Paragraph variant="secondary">
          {t("loginForm.rememberMe.description")}
        </Paragraph>
      </Wrapper>*/}
      <StyledButton
        marginBottom={["24px"]}
        onClick={onClick}
        size="large"
        variant="primary"
      >
        {t("loginForm.button.confirm")}
      </StyledButton>
      {!!availableFactors && (
        <OTPFooter
          availableFactors={availableFactors}
          factor={factor}
          fetchFactor={callBackupFactor}
        />
      )}
    </StyledContainer>
  );
};
