import { useMutation } from "@tanstack/react-query";
import { postLoginForm, trackLogin, trackLoginError } from "../utils";
import { useNavigate } from "react-router-dom";

export default function useLoginWithRedirect() {
  const navigate = useNavigate();
  return useMutation((credentials: Credentials) => postLoginForm(credentials), {
    onSuccess: (data) => {
      if (data.hasOwnProperty("stateToken")) {
        navigate("/mfa");
      } else if (data.redirectUrl) {
        trackLogin("CREDENTIALS");
        window.location.assign(data.redirectUrl);
      }
    },
    onError: () => {
      trackLoginError();
    },
  });
}
