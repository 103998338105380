import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { postPasswordReset } from "../utils";

export default function useResetPasswordWithRedirect() {
  const navigate = useNavigate();
  return useMutation(
    ({ email, recaptcha }: ResetPasswordVariables) =>
      postPasswordReset(email, recaptcha),
    {
      onSuccess() {
        navigate("/reset/password/sent");
      },
    }
  );
}
