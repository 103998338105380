import React, { useEffect, useState } from "react";
import { Center, Card, StyledLink, PatternPage } from "../components";
import styled, { useTheme } from "styled-components";
import {
  Cookie,
  Cookies,
  ErrorMessage,
  Location,
  isTastyfxLoginUrl,
  threeMinutes,
  trackNeedHelpWithThisClick,
} from "../utils";
import { useTranslation } from "react-i18next";
import { IGLogo, TastyfxLogo } from "ig-phoenix";
import { MFAEnterCode } from "./MFAEnterCode";
import { MFAAuthenticationLimit } from "./MFAAuthenticationLimit";
import useMFAWithRedirect from "../hooks/useMFAWithRedirect";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const MFAPage = () => {
  const [isBlocked, setBlocked] = useState(!!Cookie.get(Cookies.LIVE_COOKIE));
  let factorsFromCookie: FactorData[];
  if (!!Cookie.get(Cookies.FACTORS_COOKIE)) {
    factorsFromCookie = JSON.parse(
      JSON.parse(decodeURIComponent(String(Cookie.get(Cookies.FACTORS_COOKIE))))
    );
    factorsFromCookie.forEach((factor) => {
      if (factor.factorType === "token:software:totp") {
        factor.factorType = "token";
      }
    });
  }
  const { space } = useTheme();
  const { t } = useTranslation();
  const { mutate, error, isError, reset } = useMFAWithRedirect();

  if (!isBlocked) {
    if (error?.message === ErrorMessage.LIMIT_REACHED) {
      Cookie.set(Cookies.LIVE_COOKIE, threeMinutes);
      setBlocked(true);
    }
  }

  const referrer = Cookie.get(Cookies.REFERRER_COOKIE) || "";

  useEffect(() => {
    if (Cookie.get(Cookies.LIVE_COOKIE) && isBlocked) {
      const timer = setTimeout(() => {
        Cookie.remove(Cookies.LIVE_COOKIE);
        // TO DO extend location for locales -> depends of user language
        Location.replace("https://www.ig.com/uk/login");
      }, threeMinutes);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isBlocked]);

  return (
    <PatternPage>
      <Container>
        <Center>
          {isTastyfxLoginUrl(referrer) ? (
            <TastyfxLogo />
          ) : (
            <IGLogo height={space[5]} width={space[6]} />
          )}
        </Center>
        {isBlocked ? (
          <MFAAuthenticationLimit />
        ) : (
          <MFAEnterCode
            availableFactors={factorsFromCookie}
            mutate={mutate}
            isError={isError}
            reset={reset}
          />
        )}
        <Center>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={t("loginForm.helpAndSupport.link")}
            onClick={trackNeedHelpWithThisClick}
          >
            {t("loginForm.button.link")}
          </StyledLink>
        </Center>
      </Container>
    </PatternPage>
  );
};
