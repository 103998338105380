import styled from "styled-components";
import { Button, ErrorMessage, Header, Paragraph } from "ig-phoenix";
import { portraitTablet, desktop } from "@ig-phoenix/media-queries";

export const Card = styled.div(({ theme }) => {
  const { space } = theme;
  const { background, shadow } = theme.color.card;
  const { shadowBlur, shadowX, shadowSpread, shadowY } = theme.size.card;

  return {
    borderRadius: space[3],
    backgroundColor: background,
    boxShadow: `${shadowX} ${shadowY} ${shadowBlur} ${shadowSpread} ${shadow}`,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: space[3],
    [`@media${portraitTablet}`]: {
      width: "392px",
      height: "auto",
      padding: `${space[6]} ${space[5]}`,
    },
  };
});

export const StyledHeader = styled(Header)(({ theme: { space } }) => ({
  marginTop: space[5],
  marginBottom: space[4],
  fontWeight: 600,

  [`@media${desktop}`]: {
    textAlign: "left",
  },
}));

export const StyledParagraph = styled(Paragraph)(
  ({ marginBottom, marginTop, theme: { space } }) => ({
    width: "100%",
    textAlign: "justify",
    marginBottom: space[3],
    [`@media${desktop}`]: {
      textAlign: "left",
      marginTop: marginTop || space[2],
      marginBottom: marginBottom || space[6],
    },
  })
);

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const OtpInputWrapper = styled.div(({ theme: { space } }) => ({
  marginTop: `${space[5]}`,
  minHeight: "92px",
  marginBottom: space[1],
  [`@media${portraitTablet}`]: {
    marginTop: `${space[7]}`,
  },
  ["@media(max-width: 767px)"]: {
    marginTop: space[3],
  },
}));

export const StyledErrorMessage = styled(ErrorMessage)(
  ({ theme: { space } }) => ({
    marginTop: space[1],
    display: "block",
    width: "318px",
  })
);

export const StyledLink = styled.a(({ theme }) => {
  const { lineHeight, text: linkText } = theme.size.link.large;
  const { text } = theme.color.link;

  return {
    lineHeight: lineHeight,
    fontSize: linkText,
    color: text,
    "&:hover": {
      textDecoration: "underline",
    },
  };
});

export const Wrapper = styled.div(({ theme }) => ({
  minHeight: "100vh",
  width: "100vw",
}));

export const StyledContainer = styled.div`
  max-width: 100%;
  @media (max-width: 767px) {
    padding: 24px 16px 0 16px;
  }
`;

export const StyledButton = styled(Button)<{
  marginBottom?: string[];
  marginTop?: string[];
}>`
  width: 100%;
  padding: unset;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom[0] : "0px"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop[0] : "0px")};
  @media (max-width: 767px) {
    margin-bottom: ${(props) =>
      props.marginBottom ? props.marginBottom[1] : "0px"};
    margin-top: ${(props) => (props.marginTop ? props.marginTop[1] : "0px")};
  }
`;

export const ErrorMessageWrapper = styled.div`
  margin-left: 9px;
  margin-top: 2px;
`;
