import {
  ErrorMessage,
  Input,
  Label,
  LabelledField,
  Link,
  Toggle,
} from "ig-phoenix";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ErrorMessageWrapper, PatternPage, StyledButton } from "../components";
import useLoginWithRedirect from "../hooks/useLoginWithRedirect";
import { stateCookiePresent } from "../utils";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

type Props = {
  marginBottom?: string[];
  justifyContent?: string;
  display?: string;
};

type FormInputs = {
  username: string;
  password: string;
};

const Wrapper = styled.div<Props>`
  margin-bottom: ${(props) => props.marginBottom[0]};
  @media (max-width: 767px) {
    margin-bottom: ${(props) => props.marginBottom[1]};
  }
  display: ${(props) => (props.display ? props.display : "inline-block")};
  width: 100%;
  justify-content: ${(props) => props.justifyContent};
`;

const Separator = styled.hr<Props>`
  {
    background: #E0E0E0;
    border: none;
    height: 1px;
    margin-top: 0px;
    margin-bottom: ${(props) => props.marginBottom[0]};
    @media(max-width: 767px){
      margin-bottom: ${(props) => props.marginBottom[1]};
      padding
    };
  }
`;

const Container = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    padding: 0 16px 0 16px;
  }
`;

export default function LoginPage() {
  const { t } = useTranslation();
  const loginMutation = useLoginWithRedirect();
  const {
    register,
    setError,
    formState: { errors },
    clearErrors,
    handleSubmit,
    watch,
  } = useForm<FormInputs>({
    reValidateMode: "onSubmit",
    resetOptions: {
      keepErrors: false,
    },
    defaultValues: {
      username: !!localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "",
    },
  });
  const navigate = useNavigate();
  const [rememberMeChecked, setRememberMeChecked] = useState(
    !!localStorage.getItem("username")
  );
  const watchUsername = watch("username");

  const onSubmit = (data: FormInputs, event: SyntheticEvent) => {
    event.preventDefault();
    loginMutation.mutate({
      username: data.username,
      password: data.password,
    });
  };

  useEffect(() => {
    if (stateCookiePresent()) {
      navigate("/mfa");
    }
  }, []);

  useEffect(() => {
    if (loginMutation.isError) {
      setError("username", {
        type: "serverError",
        message: t("loginForm.login.error.invalidCredentials"),
      });
      setError("password", {
        type: "serverError",
        message: t("loginForm.login.error.invalidCredentials"),
      });
    }
  }, [loginMutation.isError]);

  useEffect(() => {
    if (rememberMeChecked) {
      localStorage.setItem("username", watchUsername);
    }
  }, [loginMutation.isSuccess]);

  const clearError = () => {
    clearErrors(["username", "password"]);
  };

  const onToggleClicked = (state: boolean) => {
    if (watchUsername === localStorage.getItem("username") && state === false) {
      localStorage.removeItem("username");
    }
    setRememberMeChecked(state);
  };

  return (
    <PatternPage>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelledField labelText={t("loginPage.username")} htmlFor="username">
            <Wrapper marginBottom={["16px"]}>
              <Input
                title="username"
                id="username"
                invalid={!!errors.username}
                {...register("username", {
                  onChange: clearError,
                  required: t("loginForm.login.error.usernameRequired"),
                })}
              />
              {!!errors.username && (
                <ErrorMessageWrapper>
                  <ErrorMessage id={"username"}>
                    {String(errors.username.message)}
                  </ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </Wrapper>
          </LabelledField>
          <LabelledField labelText={t("loginPage.password")} htmlFor="password">
            <Wrapper marginBottom={["12px"]}>
              <Input
                title="Password"
                id="password"
                type="password"
                invalid={!!errors.password}
                {...register("password", {
                  onChange: clearError,
                  required: t("loginForm.login.error.passwordRequired"),
                })}
              />
              {!!errors.password && (
                <ErrorMessageWrapper>
                  <ErrorMessage id={"password"}>
                    {String(errors.password.message)}
                  </ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </Wrapper>
          </LabelledField>
          <Wrapper
            marginBottom={["24px", "16px"]}
            justifyContent="flex-end"
            display="inline-flex"
          >
            <Link onClick={() => navigate("/reset/password")}>
              {t("loginPage.details.forgotten")}
            </Link>
          </Wrapper>
          <Separator marginBottom={["24px", "16px"]} />
          <Wrapper
            justifyContent="space-between"
            marginBottom={["24px"]}
            display="inline-flex"
          >
            <Label htmlFor="rememberDetails">
              {t("loginPage.details.remember")}{" "}
            </Label>
            <Toggle
              id="toggleRememberMe"
              onClick={onToggleClicked}
              checked={rememberMeChecked}
            />
          </Wrapper>
          <StyledButton
            type="submit"
            variant="primary"
            onClick={() => {}}
            marginBottom={["24px", "16px"]}
            disabled={loginMutation.isLoading}
          >
            {t("loginPage.login")}
          </StyledButton>
        </form>
        <StyledButton
          variant="secondary"
          onClick={() => {
            window.location.assign("https://www.ig.com/uk/application-form");
          }}
        >
          {t("loginPage.register")}
        </StyledButton>
      </Container>
    </PatternPage>
  );
}
