export default {
  name: "navigatorWithFallback",

  // See https://github.com/i18next/i18next-browser-languageDetector/blob/9efebe6ca0271c3797bc09b84babf1ba2d9b4dbb/src/browserLookups/navigator.js
  lookup() {
    let found = [];

    if (typeof navigator !== "undefined") {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i = 0; i < navigator.languages.length; i++) {
          const language = navigator.languages[i];
          found.push(language);
          if (language.includes("-")) {
            const pureLanguage = language.split("-")[0];
            if (
              !navigator.languages[i + 1] ||
              !navigator.languages[i + 1].startsWith(pureLanguage)
            ) {
              found.push(pureLanguage);
            }
          }
        }
      }
      // @ts-ignore
      if (navigator.userLanguage) {
        // @ts-ignore
        found.push(navigator.userLanguage);
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }

    return found.length > 0 ? found : undefined;
  },
};
