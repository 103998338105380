import React, { FC } from "react";
import { Paragraph } from "ig-phoenix";
import { StyledButton } from "./Styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  trackContinueWithAuthenticationAppClick,
  trackContinueWithEmailClick,
  trackContinueWithSMSClick,
} from "../utils";

type Props = {
  availableFactors: FactorData[];
  factor: FactorType;
  fetchFactor: ({ factor }: { factor: FactorType }) => void;
};

const CenteredParagraph = styled(Paragraph)`
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
  &:before,
  &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 48%;
    height: 1px;
    content: "\a0";
    background-color: #f0f2f3;
    margin-left: 2%;
  }
  &:before {
    margin-left: -50%;
    text-align: right;
  }
`;

export const OTPFooter: FC<Props> = ({
  availableFactors,
  factor,
  fetchFactor,
}) => {
  const { t } = useTranslation();

  if (availableFactors.length <= 1) return null;

  const onContinueWithClickHandler = (factorType: FactorType) => {
    fetchFactor({ factor: factorType });

    if (factorType === "email") {
      trackContinueWithEmailClick();
    }

    if (factorType === "sms") {
      trackContinueWithSMSClick();
    }

    if (factorType === "token" || factorType === "token:software:totp") {
      trackContinueWithAuthenticationAppClick();
    }
  };

  return (
    <>
      <CenteredParagraph>{t("loginForm.or")}</CenteredParagraph>
      {availableFactors
        .filter((af) => af.factorType !== factor)
        .map((af) => (
          <StyledButton
            marginBottom={["24px"]}
            key={af.factorId}
            onClick={() => {
              onContinueWithClickHandler(af.factorType);
            }}
            size="large"
            variant="tertiary"
          >
            {t(`loginForm.button.${af.factorType}`)}
          </StyledButton>
        ))}
    </>
  );
};
