import { useMutation } from "@tanstack/react-query";
import { getClientDetails, TastyProductCodes, trackLoginError } from "../utils";

export default function useClientDetails() {
  return useMutation(() => getClientDetails(), {
    onError: () => {
      trackLoginError();
    },
  });
}
