import React, { useEffect } from "react";
import {
  RECAPTCHA_APP_KEY,
  configureCastle,
  initialiseGoogleAnalytics,
  Cookie,
  Cookies,
} from "./utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StyleProvider } from "ig-phoenix";
import LoginPage from "./pages/LoginPage";
import LoginPageV2 from "./pages/LoginPageV2";

import "./i18n";
import { MemoryRouter, Route, Routes } from "react-router-dom";
import { MFAPage } from "./pages/MFAPage";
import { MFAPageV2 } from "./pages/MFAPageV2";
import { DevPanel, Wrapper } from "./components";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { ResetPasswordSentPage } from "./pages/ResetPasswordSentPage";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LoginErrorPage } from "./pages/LoginErrorPage";

const queryClient = new QueryClient();

export default function Root() {
  useEffect(() => {
    initialiseGoogleAnalytics();
    configureCastle();
  }, []);

  const newFlow = Cookie.get(Cookies.NEW_FLOW_COOKIE) || false;
  // TODO: Change colorScheme to Auto once tokens are added
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_APP_KEY}>
      <StyleProvider themeSet="IGWeb2024" colorScheme="Light" withFonts>
        <QueryClientProvider client={queryClient}>
          <Wrapper>
            <MemoryRouter>
              <DevPanel />
              <Routes>
                <Route path="/mfa" element={<MFAPage />} />
                <Route path="/mfav2" element={<MFAPageV2 />} />
                <Route path="/loginv2" element={<LoginPageV2 />} />
                <Route path="/reset/password" element={<ResetPasswordPage />} />
                <Route
                  path={"/reset/password/sent"}
                  element={<ResetPasswordSentPage />}
                />
                <Route
                  path="/"
                  element={newFlow ? <LoginPageV2 /> : <LoginPage />}
                />
                <Route path="/error/page" element={<LoginErrorPage />} />
              </Routes>
            </MemoryRouter>
          </Wrapper>
        </QueryClientProvider>
      </StyleProvider>
    </GoogleReCaptchaProvider>
  );
}
