import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PatternPage,
  StyledButton,
  StyledHeader,
  StyledParagraph,
} from "../components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    padding: 0 16px 0 16px;
  }
`;

export const ResetPasswordSentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <PatternPage>
      <Container>
        <StyledHeader size="larger" variant="h2">
          {t("resetPasswordSentPage.title")}
        </StyledHeader>
        <StyledParagraph variant="secondary">
          {t("resetPasswordSentPage.paragraph")}
        </StyledParagraph>
        <StyledButton variant="primary" onClick={handleClick}>
          {t("resetPasswordSentPage.button")}
        </StyledButton>
      </Container>
    </PatternPage>
  );
};
