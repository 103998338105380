import { useTranslation } from "react-i18next";
import {
  PatternPage,
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledParagraph,
} from "../components";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useClientDetails from "../hooks/useClientDetails";
import { getTastyPlatformUrl, TastyProductCodes } from "../utils";

export const LoginErrorPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clientDetailsMutation = useClientDetails();
  const [isTasty, setIsTasty] = useState(false);
  const [tastyAccountId, setTastyAccountId] = useState<string>("");

  useEffect(() => {
    clientDetailsMutation
      .mutateAsync()
      .then((data) => {
        const tastyAccount = data.accounts.find(
          ({ productCode }) =>
            productCode === TastyProductCodes.TASTYCASH ||
            productCode === TastyProductCodes.TASTYOPT
        );
        setTastyAccountId(tastyAccount.accountId);
        setIsTasty(!!tastyAccount);
      })
      .catch(() => {
        //DO NOTHING
        //This is done to not throw an error on the user's screen
      });
  }, []);

  if (clientDetailsMutation.isLoading) {
    return <>Loading...</>;
  }

  const goToTastyPlatform = (e) => {
    e.preventDefault();
    window.location.href = getTastyPlatformUrl(tastyAccountId);
  };

  return (
    <PatternPage>
      <StyledContainer>
        <StyledHeader size="larger" variant="h2">
          {t("errorPage.title")}
        </StyledHeader>
        <StyledParagraph variant="secondary" marginBottom="16px">
          {t("errorPage.paragraph.default")}
        </StyledParagraph>
        {isTasty && (
          <>
            <StyledParagraph variant="secondary" marginTop="0px">
              {t("errorPage.paragraph.tasty")}
            </StyledParagraph>
            <StyledButton
              variant="secondary"
              onClick={goToTastyPlatform}
              marginBottom={["24px", "16px"]}
            >
              {t("errorPage.button.tasty")}
            </StyledButton>
          </>
        )}
        <StyledButton
          variant="unarmed"
          onClick={() => {
            navigate("/");
          }}
        >
          {t("errorPage.button.backToLogin")}
        </StyledButton>
      </StyledContainer>
    </PatternPage>
  );
};
