import React, { FC, useEffect } from "react";
import { useCountdown } from "../hooks/useCountdown";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { StyledLink, StyledParagraph } from "./Styled";

type Props = {
  factor: FactorType;
  fetchFactor: ({ factor }: { factor: FactorType }) => void;
};

const formatTime = (count: number): string => {
  const seconds = count % 60;
  return `${Math.floor(count / 60)}:${
    seconds === 0 ? "00" : seconds < 10 ? `0${seconds}` : seconds
  }`;
};

export const OTPBackupFactorCounter: FC<Props> = ({ factor, fetchFactor }) => {
  const { t } = useTranslation();
  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({ countStart: 120 });

  useEffect(() => {
    startCountdown();
    return () => {
      stopCountdown();
    };
  }, []);

  useEffect(() => {
    resetCountdown();
    startCountdown();
  }, [factor]);

  if (count > 0)
    return (
      <StyledParagraph variant="secondary">
        {t(`loginForm.description.${factor}.codeCountdown`, {
          time: formatTime(count),
        })}
      </StyledParagraph>
    );

  return (
    <StyledLink
      onClick={() => {
        fetchFactor({ factor });
        resetCountdown();
      }}
    >
      {t(`loginForm.description.${factor}.resendCode`)}
    </StyledLink>
  );
};
