import { ReactElement } from "react";

export const IgLogoLarge = (): ReactElement => (
  <svg
    width="138px"
    height="120px"
    viewBox="0 0 138 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.574 0.100113C82.0449 0.491866 75.5025 2.01527 69.4602 4.55076C61.9837 7.68803 55.513 12.1085 49.855 17.9442C44.855 23.1013 41.0951 28.5471 38.1898 34.8402C35.2419 41.2256 33.5903 47.4767 32.8618 55.0072C32.7066 56.6109 32.7085 63.4179 32.8646 65.0984C33.3369 70.1834 34.258 74.6474 35.7653 79.1558C38.6707 87.8462 43.2486 95.3384 49.7672 102.072C56.0565 108.568 63.1105 113.19 71.566 116.356C85.1826 121.453 100.632 121.197 115.529 115.625C123.427 112.672 131.1 107.925 136.314 102.77L137.049 102.043V76.6556V51.2681H113.48H89.9107V61.8548V72.4415H102.876H115.841V81.7457V91.0499L115.373 91.3601C113.397 92.6706 110.259 94.3086 107.983 95.2185C104.144 96.7529 100.594 97.6553 96.3265 98.1814C95.3355 98.3036 94.3491 98.3412 92.0938 98.3428C89.0182 98.3448 88.2871 98.292 85.8117 97.8886C74.0656 95.9745 63.7981 88.3793 58.189 77.4552C54.0403 69.3752 52.9505 59.742 55.1705 50.7726C57.4027 41.7535 63.2116 33.479 71.0642 28.133C76.0727 24.7232 81.7126 22.6055 87.8476 21.8311C89.3849 21.6372 94.018 21.6103 95.4354 21.7872C101.85 22.5877 107.263 24.6565 112.723 28.3937C114.957 29.9232 117.342 31.8967 118.888 33.4948L119.662 34.2953L127.212 26.6613L134.762 19.0272L133.898 18.0963C131.979 16.0274 128.997 13.3543 126.501 11.4648C122.463 8.40765 117.837 5.8245 112.99 3.91925C105.448 0.955158 96.8187 -0.394713 88.574 0.100113ZM0 60.0078V117.131H10.8713H21.7425V60.0078V2.88464H10.8713H0V60.0078Z"
      fill="white"
    />
  </svg>
);

export const IgLogoSmall = (): ReactElement => (
  <svg
    width="74px"
    height="64px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1266 0C49.0242 0 48.9239 -1.07747e-06 48.8215 0.00127725C40.7293 0.0737489 33.0293 3.28207 27.14 9.03525C21.2499 14.7887 17.8184 22.4557 17.4781 30.6237C17.1112 39.424 20.2318 47.7726 26.2659 54.1309C32.3052 60.4955 40.4223 64 49.122 64C58.0486 64 67.238 60.3361 73.1429 54.4306V27.344H47.989V38.628H61.8227V48.5555L61.6729 48.6548C58.1912 50.9741 53.6545 52.4728 49.122 52.4728C43.5163 52.4728 38.2968 50.203 34.4261 46.0822C30.5598 41.9657 28.5927 36.5753 28.8872 30.9042C29.4198 20.6348 37.8093 12.1369 47.9866 11.558C53.644 11.2282 59.0368 13.5718 63.3357 17.7374C63.4883 17.8854 63.661 18.0705 63.8598 18.299L71.926 10.1488C71.7463 9.95251 71.5701 9.76282 71.4028 9.58641C65.6652 3.53931 57.8335 0 49.1266 0ZM0 62.4855H11.6327V1.51453H0V62.4855Z"
      fill="white"
    />
  </svg>
);
