import { Grid } from "ig-phoenix";
import { useIsSmallScreen } from "@ig-phoenix/style-provider";
import { FC, ReactNode } from "react";
import styled from "styled-components";
import { IgLogoLarge, IgLogoSmall } from "../icons/IgLogo";
import { TastyLogoSmall, TastyLogoLarge } from "../icons/TastyLogo";

import { Cookie, Cookies, isTastyfxLoginUrl } from "../utils";

type ContentContainerProps = {
  backgroundColor?: string;
  borderRadiusTopLeft?: string;
  borderRadiusBottomLeft?: string;
  borderRadiusTopLeftMobile?: string;
  borderRadiusTopRightMobile?: string;
};

const ContentContainer = styled(Grid.Item)<ContentContainerProps>`
  background-color: ${(props) => props.backgroundColor};
  @media (min-width: 768px) {
    border-top-left-radius: ${(props) => props.borderRadiusTopLeft};
    border-bottom-left-radius: ${(props) => props.borderRadiusBottomLeft};
  }
  @media (max-width: 767px) {
    border-top-left-radius: ${(props) => props.borderRadiusTopLeftMobile};
    border-top-right-radius: ${(props) => props.borderRadiusTopRightMobile};
  }
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    min-height: 100vh;
  }
  height: 100%;
  width: 100%;
`;

const StyledGrid = styled(Grid)`
  width: 100vw;
  height: 100vh;
  background-color: #e61e1e;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  children: ReactNode;
};

const referrer = Cookie.get(Cookies.REFERRER_COOKIE) || "";

export const PatternPage: FC<Props> = ({ children }) => {
  const isMobile = useIsSmallScreen();
  return (
    <StyledGrid
      xlGridTemplateColumns="1fr 1fr 0.75fr 1.5fr 0.75fr"
      xlGridTemplateRows="1fr"
      lgGridTemplateColumns="1fr 1fr 0.75fr 1.5fr 0.75fr"
      lgGridTemplateRows="1fr"
      mdGridTemplateColumns="1fr 1fr 0.75fr 1.5fr 0.75fr"
      mdGridTemplateRows="1fr"
      smGridTemplateColumns="1fr 1fr 0.75fr 1.5fr 0.75fr"
      smGridTemplateRows="1fr"
      xsGridTemplateColumns="1fr 1fr 1fr"
      xsGridTemplateRows="1fr 1fr"
    >
      <Grid.NestedItem
        xlColStart="1"
        xlColEnd="3"
        xlRowStart="1"
        xlRowEnd="1"
        lgColStart="1"
        lgColEnd="3"
        lgRowStart="1"
        lgRowEnd="1"
        mdColStart="1"
        mdColEnd="3"
        mdRowStart="1"
        mdRowEnd="1"
        smColStart="1"
        smColEnd="3"
        smRowStart="1"
        smRowEnd="1"
        xsColStart="1"
        xsColEnd="4"
        xsRowStart="1"
        xsRowEnd="1"
      >
        <Grid
          xlGridTemplateColumns="1fr 1fr 1fr"
          xlGridTemplateRows="1fr 1fr 1fr"
          lgGridTemplateColumns="1fr 1fr 1fr"
          lgGridTemplateRows="1fr 1fr 1fr"
          mdGridTemplateColumns="1fr 1fr 1fr"
          mdGridTemplateRows="1fr 1fr 1fr"
          smGridTemplateColumns="1fr 1fr 1fr"
          smGridTemplateRows="1fr 1fr 1fr"
          xsGridTemplateColumns="1fr 1fr 1fr"
          xsGridTemplateRows="1fr 1fr 1fr"
        >
          <Grid.NestedItem
            xlColStart="2"
            xlColEnd="2"
            xlRowStart="2"
            xlRowEnd="2"
            lgColStart="2"
            lgColEnd="2"
            lgRowStart="2"
            lgRowEnd="2"
            mdColStart="2"
            mdColEnd="2"
            mdRowStart="2"
            mdRowEnd="2"
            smColStart="2"
            smColEnd="2"
            smRowStart="2"
            smRowEnd="2"
            xsColStart="1"
            xsColEnd="1"
            xsRowStart="3"
            xsRowEnd="3"
          >
            <Container>
              {isTastyfxLoginUrl(referrer) ? (
                isMobile ? (
                  <TastyLogoSmall />
                ) : (
                  <TastyLogoLarge />
                )
              ) : isMobile ? (
                <IgLogoSmall />
              ) : (
                <IgLogoLarge />
              )}
            </Container>
          </Grid.NestedItem>
        </Grid>
      </Grid.NestedItem>
      <ContentContainer
        lgColStart="3"
        lgColEnd="3"
        mdColStart="3"
        mdColEnd="3"
        backgroundColor="#FFFFFF"
        borderRadiusTopLeft="28px"
        borderRadiusBottomLeft="28px"
        smColStart="3"
        smColEnd="3"
      />
      <ContentContainer
        lgColStart="4"
        lgColEnd="4"
        lgRowStart="1"
        lgRowEnd="1"
        mdColStart="4"
        mdColEnd="4"
        mdRowStart="1"
        mdRowEnd="1"
        smColStart="4"
        smColEnd="4"
        smRowStart="1"
        smRowEnd="1"
        xsColStart="1"
        xsColEnd="4"
        xsRowStart="2"
        xsRowEnd="2"
        borderRadiusTopLeftMobile="15px"
        borderRadiusTopRightMobile="15px"
        backgroundColor="#FFFFFF"
      >
        {children}
      </ContentContainer>
      <ContentContainer
        backgroundColor="#FFFFFF"
        lgColStart="5"
        lgColEnd="5"
        mdColStart="5"
        mdColEnd="5"
        smColStart="5"
        smColEnd="5"
      />
    </StyledGrid>
  );
};
