import { useMutation } from "@tanstack/react-query";
import {
  postLoginFormv2,
  trackLogin,
  trackLoginError,
  getMyIGPath,
  LoginMfeError,
} from "../utils";
import { useNavigate } from "react-router-dom";

export default function useLoginWithRedirectv2() {
  const navigate = useNavigate();
  return useMutation(
    (credentials: Credentials) => postLoginFormv2(credentials),
    {
      onSuccess: (data) => {
        if (data.hasOwnProperty("factors")) {
          navigate("/mfav2");
        } else {
          trackLogin("CREDENTIALS");
          window.location.assign(getMyIGPath());
        }
      },
      onError: (error: LoginMfeError) => {
        trackLoginError();
        const errorStatusCode = error.statusCode;
        if (errorStatusCode >= 500 && errorStatusCode <= 599) {
          navigate("/error/page");
        }
      },
    }
  );
}
