import { StyledButton, StyledHeader, StyledParagraph } from "../components";
import { useTranslation } from "react-i18next";

export const MFAAuthenticationLimit = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledHeader size="larger" variant="h2">
        {t("loginForm.login.error.authentication.title")}
      </StyledHeader>
      <StyledParagraph variant="secondary">
        {t("loginForm.login.error.authentication.paragraph")}
      </StyledParagraph>
      <StyledParagraph variant="secondary" marginBottom="48px">
        {t("loginForm.login.error.authentication.paragraphB")}
      </StyledParagraph>
      <StyledButton
        marginBottom={["24px"]}
        onClick={() => {
          //TO DO locales for dynamic login link -> depends on user language
          location.href = "https://www.ig.com/uk/login";
        }}
        size="large"
        variant="primary"
      >
        {t("loginForm.login.error.authentication.button")}
      </StyledButton>
    </>
  );
};
