import { useMutation } from "@tanstack/react-query";
import {
  getMyIGPath,
  LoginMethod,
  postMFAv2,
  trackLogin,
  trackLoginError,
} from "../utils";

const factorToLoginMethod: Record<FactorType, LoginMethod> = {
  email: "EMAIL",
  sms: "SMS",
  token: "OTP",
  "token:software:totp": "OTP",
};
export default function useMFAWithRedirectv2() {
  return useMutation(
    ({ token, factor }: OTPMutationVariables) => {
      return postMFAv2(token, factor);
    },
    {
      onSuccess: (data) => {
        trackLogin(factorToLoginMethod["token"]);
        window.location.assign(getMyIGPath());
      },
      onError: (_error: Error) => {
        trackLoginError();
      },
    }
  );
}
