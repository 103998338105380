import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "../public/assets/locales/en.json";
import translationDE from "../public/assets/locales/de.json";
import translationES from "../public/assets/locales/es.json";
import translationFR from "../public/assets/locales/fr.json";
import translationIT from "../public/assets/locales/it.json";
import translationJA from "../public/assets/locales/ja.json";
import translationNL from "../public/assets/locales/nl.json";
import translationNO from "../public/assets/locales/no.json";
import translationPT from "../public/assets/locales/pt.json";
import translationSE from "../public/assets/locales/sv.json";
import translationZH_CN from "../public/assets/locales/zh_CN.json";
import translationZH_TW from "../public/assets/locales/zh_TW.json";
import navigatorWithFallbackLanguageDetector from "./navigatorWithFallbackLanguageDetector";

const languageDetector = new LanguageDetector();
languageDetector.addDetector(navigatorWithFallbackLanguageDetector);

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  nl: {
    translation: translationNL,
  },
  no: {
    translation: translationNO,
  },
  pt: {
    translation: translationPT,
  },
  sv: {
    translation: translationSE,
  },
  "zh-CN": {
    translation: translationZH_CN,
  },
  "zh-TW": {
    translation: translationZH_TW,
  },
};

const DETECTION_OPTIONS = {
  order: ["navigatorWithFallback"],
};

i18next
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection: DETECTION_OPTIONS, //detector options
    resources,
    supportedLngs: Object.keys(resources),
    fallbackLng: "en", //default language
  });

export default i18next;
