import React, { FC } from "react";
import { StyledHeader, StyledParagraph } from "./Styled";
import { Cookie, Cookies } from "../utils";
import { useTranslation } from "react-i18next";

type Props = {
  factor: FactorType;
  context: string;
};
export const OTPDescription: FC<Props> = ({ context, factor }) => {
  const { t } = useTranslation();
  const passwordWithOtpUsed =
    Cookie.get(Cookies.PASSWORD_WITH_OTP_USED) === "true";
  return (
    <>
      <StyledHeader size="larger" variant="h2">
        {passwordWithOtpUsed
          ? t("loginForm.description.processChanged.title")
          : t(`loginForm.description.${factor}.title`)}
      </StyledHeader>
      <StyledParagraph variant="secondary">
        {passwordWithOtpUsed
          ? t("loginForm.description.processChanged.paragraph")
          : t(`loginForm.description.${factor}.paragraph`, { factor: context })}
      </StyledParagraph>
      {passwordWithOtpUsed && (
        <StyledParagraph variant="secondary">
          {t("loginForm.description.processChanged.paragraphB")}
        </StyledParagraph>
      )}
    </>
  );
};
