import { Label, Toggle } from "ig-phoenix";
import React, { useState } from "react";
import { Cookie, Cookies, getEnv, oneHour } from "../utils";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PANEL = styled.aside`
  position: relative;
  background-color: orange;
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
  margin-bottom: auto;
`;

const Wrapper = styled.div`
  display: {"inline-block"};
  justify-content: {"center"};
`;
export const DevPanel = () => {
  const env = getEnv();

  const [newFlowChecked, setNewFlowChecked] = useState(
    !!Cookie.get(Cookies.NEW_FLOW_COOKIE)
  );

  const onToggleClicked = (state: boolean) => {
    if (Cookie.get(Cookies.NEW_FLOW_COOKIE) && state === false) {
      Cookie.remove(Cookies.NEW_FLOW_COOKIE);
    } else if (state === true) {
      Cookie.set(Cookies.NEW_FLOW_COOKIE, oneHour);
    }
    location.reload();
    setNewFlowChecked(state);
  };

  if (env !== "DEV") return null;
  return (
    <PANEL>
      <Link to="/">LOGIN</Link>
      <Link to="/mfa">MFA</Link>
      <Wrapper>
        <Label htmlFor="newLoginFlow">New Login Flow</Label>
        <Toggle
          id="toggleNewLoginFlow"
          onClick={onToggleClicked}
          checked={newFlowChecked}
        />
      </Wrapper>
      <Link to="/reset/password">Reset Password</Link>
      <Link to="/reset/password/sent">Password Reset Sent</Link>
      <Link to="/error/page">Error Page</Link>
    </PANEL>
  );
};
