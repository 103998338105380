import { useMutation } from "@tanstack/react-query";
import { LoginMethod, postMFA, trackLogin, trackLoginError } from "../utils";

const factorToLoginMethod: Record<FactorType, LoginMethod> = {
  email: "EMAIL",
  sms: "SMS",
  token: "OTP",
  "token:software:totp": "OTP",
};
export default function useMFAWithRedirect() {
  return useMutation(
    ({ token, stateToken, factor }: OTPMutationVariables) => {
      return postMFA(token, stateToken, factor);
    },
    {
      onSuccess: (data) => {
        trackLogin(factorToLoginMethod[data.factor]);
        if (data.redirectUrl) {
          window.location.assign(data.redirectUrl);
        }
      },
      onError: (_error: Error) => {
        trackLoginError();
      },
    }
  );
}
