import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  ErrorMessageWrapper,
  PatternPage,
  StyledButton,
  StyledHeader,
  StyledParagraph,
} from "../components";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Input, LabelledField } from "ig-phoenix";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import useResetPasswordWithRedirect from "../hooks/useResetPasswordWithRedirect";
import styled from "styled-components";

const StyledForm = styled.form`
  padding: 0;
  @media (max-width: 767px) {
    padding: 0 16px 0 16px;
  }
`;

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [refreshReCaptcha, setRefreshCaptcha] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: "onSubmit", reValidateMode: "onSubmit" });
  const resetPasswordMutation = useResetPasswordWithRedirect();

  const onSubmit = (data, e: SyntheticEvent) => {
    e.preventDefault();
    resetPasswordMutation.mutate({
      email: data.email,
      // @ts-ignore
      recaptcha: recaptchaToken,
    });
  };

  useEffect(() => {
    if (resetPasswordMutation.isError) {
      setRefreshCaptcha(!refreshReCaptcha);
    }
  }, [resetPasswordMutation.isError]);

  return (
    <PatternPage>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader size="larger" variant="h2">
          {t("resetPasswordPage.title")}
        </StyledHeader>
        <StyledParagraph variant="secondary">
          {t("resetPasswordPage.paragraph")}
        </StyledParagraph>
        <LabelledField labelText={t("resetPasswordPage.email")} htmlFor="email">
          <Input
            type="email"
            title="email"
            id="email"
            {...register("email", {
              required: t("resetPasswordPage.emailRequired"),
            })}
            invalid={!!errors.email}
            onChange={() => clearErrors(["email"])}
          />
          {!!errors.email && (
            <ErrorMessageWrapper>
              <ErrorMessage id="email">
                {String(errors.email.message)}
              </ErrorMessage>
            </ErrorMessageWrapper>
          )}
        </LabelledField>
        <GoogleReCaptcha
          onVerify={setRecaptchaToken}
          refreshReCaptcha={refreshReCaptcha}
        />
        <StyledButton
          type="submit"
          marginTop={["16px"]}
          variant="primary"
          onClick={() => {}}
          disabled={resetPasswordMutation.isLoading}
        >
          {t("resetPasswordPage.button")}
        </StyledButton>
      </StyledForm>
    </PatternPage>
  );
}
