import { encryptPassword } from "./encryption";
import {
  Cookie,
  Cookies,
  getEnv,
  loginErrorPageCookiePresent,
  LoginMfeError,
  oneDay,
  threeMinutes,
} from "./helpers";
import { createRequestToken } from "@castleio/castle-js";
import faultyIdTokenFile from "./faulty-id-token.json";

export const X_DEVICE_USER_AGENT_HEADER_VAULE =
  "vendor=IG Group | applicationType=ig | platform=MyIG | version=1.0.0";

const URL_MAP: Record<ENV, string> = Object.freeze({
  LIVE: "https://www.ig.com",
  DEMO: "https://demo.ig.com",
  UAT: "https://web.ig.com",
  TEST: "https://net.ig.com",
  DEV: "https://web.ig.com",
});

const AWS_URL_MAP: Record<ENV, string> = Object.freeze({
  LIVE: "https://login.ig.com",
  DEMO: "https://demo-login.ig.com",
  UAT: "https://web-login.ig.com",
  TEST: "https://net-login.ig.com",
  DEV: "https://web-login.ig.com",
});

const TASTY_PLATFORM_URL_MAP: Record<ENV, string> = Object.freeze({
  DEV: "https://ig.staging-tasty.works/oauth-landing.html",
  UAT: "https://ig.staging-tasty.works/oauth-landing.html",
  TEST: "https://ig.staging-tasty.works/oauth-landing.html",
  DEMO: "https://ig.tastytrade.com/oauth-landing.html",
  LIVE: "https://ig.tastytrade.com/oauth-landing.html",
});

export const getTastyPlatformUrl = (tastyAccountId: string) => {
  return `${TASTY_PLATFORM_URL_MAP[getEnv()]}?accountNumber=${tastyAccountId}`;
};

const getBaseValidatePath = (): string => {
  return `${URL_MAP[getEnv()]}/uk/validate/api`;
};

const getBaseCSGPath = (): string => {
  return `${URL_MAP[getEnv()]}/clientsecurity`;
};

const getBaseAuthenticationService = (): string => {
  return `${AWS_URL_MAP[getEnv()]}/api/authentication-service`;
};

const getBaseClientDetailsServicePath = (): string => {
  return `${AWS_URL_MAP[getEnv()]}/api/client-details-service`;
};

const getBaseClientSecurityPath = (): string => {
  return `${URL_MAP[getEnv()]}/clientsecurity`;
};

export const getMyIGPath = (): string => {
  return `${URL_MAP[getEnv()]}/uk/myig/dashboard`;
};

const verifyRes = (res: Response) => {
  if (!res.ok) {
    const error: LoginMfeError = new Error(res.statusText);

    error.statusCode = res.status;
    throw error;
  }

  return res;
};

export async function postLoginForm(
  credentials: Credentials
): Promise<SuccessLoginResponse | MFALoginResponse> {
  const { encryptionKey, timeStamp } = await getEncryptionKey();

  const castleRequestToken: string = await createRequestToken();

  const encryptedPassword = await encryptPassword(
    credentials.password,
    encryptionKey,
    timeStamp
  );

  return fetch(`${getBaseValidatePath()}/session/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-castle-request-token": castleRequestToken,
    },
    body: JSON.stringify({
      username: credentials.username,
      password: encryptedPassword,
      enc: true,
      advertisingId: null,
    }),
  })
    .then(verifyRes)
    .then((res) => {
      return res.json();
    })
    .then((json: LoginRes) => {
      if (!json.success) {
        throw new Error((json as FailureLoginResponse).reasons.join("\n"));
      }

      return json;
    });
}

export async function postLoginFormv2(
  credentials: Credentials
): Promise<SuccessSessionResponse | SuccessAuthenticationResponse> {
  const { encryptionKey, timeStamp } = await getEncryptionKeyv2();

  const encryptedPassword = await encryptPassword(
    credentials.password,
    encryptionKey,
    timeStamp
  );

  const authResponse = await getIdToken(
    credentials.username,
    encryptedPassword
  );

  if (authResponse.status == "AUTHENTICATED") {
    const authorizationToken = "Bearer " + authResponse.tokens.idToken.token;
    return postSession(authorizationToken);
  } else if (authResponse.status == "MULTI_FACTOR_REQUIRED") {
    await Cookie.setValue(
      Cookies.FACTORS_COOKIE,
      JSON.stringify(authResponse.factors),
      threeMinutes
    );
    return authResponse;
  }
}

export async function getClientDetails(): Promise<ClientDetailsServiceResponse> {
  return fetch(`${getBaseClientDetailsServicePath()}/v1/clients/current`)
    .then(verifyRes)
    .then((res) => res.json())
    .then((json): ClientDetailsServiceResponse => json);
}

export async function getEncryptionKey() {
  return fetch(`${getBaseValidatePath()}/encryptionkey`)
    .then(verifyRes)
    .then((res) => res.json());
}

export async function getEncryptionKeyv2() {
  return fetch(`${getBaseAuthenticationService()}/encryptionkey`)
    .then(verifyRes)
    .then((res) => res.json());
}

export async function getIdToken(username: string, encryptedPassword: string) {
  return fetch(`${getBaseAuthenticationService()}/v1/authenticate`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: encryptedPassword,
    }),
  })
    .then(verifyRes)
    .then((res) => {
      return res.json();
    })
    .then((json: SuccessAuthenticationResponse) => {
      return json;
    });
}

export async function postMFA(
  token: string,
  stateToken: string,
  factor: FactorType
): Promise<SuccessMfaResponse & { factor: FactorType }> {
  const castleRequestToken: string = await createRequestToken();

  return fetch(`${getBaseValidatePath()}/mfa/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-castle-request-token": castleRequestToken,
    },
    body: JSON.stringify({
      stateToken,
      passCode: token,
    }),
    credentials: "include",
  })
    .then(verifyRes)
    .then((res) => {
      return res.json();
    })
    .then((json: MFARes) => {
      if (!json.success) {
        throw new Error((json as FailureLoginResponse).reasons.join("\n"));
      }

      return {
        ...json,
        factor,
      };
    });
}

export async function postMFAv2(
  token: string,
  factor: FactorType
): Promise<SuccessSessionResponse> {
  const castleRequestToken: string = await createRequestToken();
  var authorizationToken;

  await fetch(`${getBaseAuthenticationService()}/v1/mfa/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-castle-request-token": castleRequestToken,
    },
    body: JSON.stringify({
      passCode: token,
    }),
    credentials: "include",
  })
    .then(verifyRes)
    .then((res) => {
      return res.json();
    })
    .then((json: SuccessAuthenticationResponse) => {
      if (json.status == "AUTHENTICATED") {
        authorizationToken = "Bearer " + json.tokens.idToken.token;
      }
    });

  return postSession(authorizationToken);
}

export async function postPasswordReset(
  email: string,
  recaptcha: string
): Promise<any> {
  return fetch(`${getBaseClientSecurityPath()}/v2/password/reset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-device-user-agent": X_DEVICE_USER_AGENT_HEADER_VAULE,
    },
    body: JSON.stringify({
      email: email,
      gRecaptchaResponse: recaptcha,
    }),
    credentials: "include",
  }).then((response) => verifyRes(response));
}

// TODO Adjust the function after the MW is implemented
export async function postBackupFactor(
  factorId: string
): Promise<BackupFactorResponse> {
  return fetch(`${getBaseValidatePath()}/mfa/backup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-device-user-agent": X_DEVICE_USER_AGENT_HEADER_VAULE,
    },
    body: JSON.stringify({
      stateToken: Cookie.get(Cookies.STATE_TOKEN),
      factorId,
    }),
    credentials: "include",
  })
    .then(verifyRes)
    .then((res) => {
      return res.json();
    });
}

export async function postBackupFactorv2(
  factorId: string
): Promise<BackupFactorResponse> {
  return fetch(`${getBaseAuthenticationService()}/v1/mfa/backup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-device-user-agent": X_DEVICE_USER_AGENT_HEADER_VAULE,
    },
    body: JSON.stringify({
      factorId,
    }),
    credentials: "include",
  })
    .then(verifyRes)
    .then((res) => {
      return res.json();
    });
}

export async function postSession(authorizationToken: string) {
  return fetch(`${getBaseCSGPath()}/v4/session`, {
    method: "POST",
    credentials: "include",
    headers: {
      accept: "*/*",
      Authorization: loginErrorPageCookiePresent()
        ? faultyIdTokenFile["faulty-id-token"]
        : authorizationToken,
    },
  })
    .then(verifyRes)
    .then((res) => {
      return res.json();
    })
    .then((json: SuccessSessionResponse) => {
      if (json.authenticationStatus != "AUTHENTICATED") {
        throw new Error(json.authenticationStatus);
      }
      Cookie.setValue(Cookies.CST, json.clientSecurityToken, oneDay);
      Cookie.setValue(Cookies.XST, json.accountSecurityToken, oneDay);

      return json;
    });
}
