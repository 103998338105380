import * as Castle from "@castleio/castle-js";

export function configureCastle() {
  let authorisedCastleToken = "";

  if (
    window.location.hostname.startsWith("demo") ||
    window.location.hostname.startsWith("login")
  ) {
    authorisedCastleToken = "pk_NBvxiEusM8ZHUz6pYjqTczXKxZ1YsvdR";
  } else {
    authorisedCastleToken = "pk_xgRypUpupL1HjgsyhHV2syYy6KvB7nao";
  }

  Castle.configure({ pk: authorisedCastleToken });
}
