export enum Cookies {
  LIVE_COOKIE = "LIVE-COOKIE",
  STATE_TOKEN = "STATE-TOKEN",
  FACTORS_COOKIE = "FACTORS",
  PASSWORD_WITH_OTP_USED = "passwordWithOtpUsed",
  REFERRER_COOKIE = "referrer",
  NEW_FLOW_COOKIE = "newFlow",
  LOGIN_ERROR_PAGE = "loginErrorPage",
  CST = "CST",
  XST = "X-SECURITY-TOKEN",
  SESSION = "SESSION",
  ACCESS_TOKEN = "ACCESS-TOKEN",
  REFRESH_TOKEN = "REFRESH-TOKEN",
  IG_ENVIRONMENT = "IG-ENVIRONMENT",
  SESSION_OPEN = "sessionOpen",
  EXIT_PATH = "exitPath",
  EXIT_URL = "exitUrl",
  TRACKING_ID = "ID",
  CALLER_REQ_ID = "callerReqId",
  DEVICE_OS = "deviceOs",
  DEVICE_TYPE = "deviceType",
  DEFAULT_DEALING_PLATFORM = "defaultDealingPlatform",
  PREFERRED_ACCOUNT_ID = "preferredAccountId",
}

export enum ErrorMessage {
  LIMIT_REACHED = "service.security.authentication.verification-attempts-limit-reached",
}

export const RECAPTCHA_APP_KEY = "6LcyriwqAAAAACZ3qKFG4Y_HsPv1mqlV5Z-64dKN";

export const Cookie = {
  remove: (cookieName: Cookies) => {
    document.cookie = `${cookieName}='';max-age=-1`;
  },
  set: (cookieName: Cookies, livespan: number) => {
    const expiresDate = new Date(Date.now() + livespan).toUTCString();

    document.cookie = `${cookieName}=true;expires=${expiresDate}}`;
  },
  setValue: (cookieName: Cookies, value: String, livespan: number) => {
    const expiresDate = new Date(Date.now() + livespan).toUTCString();
    document.cookie = `${cookieName}=${value};expires=${expiresDate};domain=.ig.com;path=/`;
  },
  get: (cookieName: Cookies): string | void => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookieName}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  },
};

export function getEnv(): ENV {
  switch (window.location.hostname) {
    case "login.ig.com":
      return "LIVE";
    case "demo-login.ig.com":
      return "DEMO";
    case "web-login.ig.com":
      return "UAT";
    case "net-login.ig.com":
      return "TEST";
    default:
      return "DEV";
  }
}

// for unit tests purpose
export const Location = {
  replace(url: string) {
    window.location.replace(url);
  },
};

export const threeMinutes = 0.3 * 60 * 1000;
export const oneHour = 60 * 1000;
export const oneDay = 24 * 60 * 1000;

export function stateCookiePresent(): boolean {
  return Cookie.get(Cookies.STATE_TOKEN) !== undefined;
}

export const isTastyfxLoginUrl = (referrer: string) => {
  return /tastyfx\/login/.test(referrer);
};

export enum TastyProductCodes {
  TASTYOPT = "TASTYOPT",
  TASTYCASH = "TASTYCASH",
}

export interface LoginMfeError extends Error {
  statusCode?: number;
}

export function loginErrorPageCookiePresent(): boolean {
  return Cookie.get(Cookies.LOGIN_ERROR_PAGE) !== undefined;
}
