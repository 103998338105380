import { ReactElement } from "react";

export const TastyLogoSmall = (): ReactElement => (
  <svg
    width="200px"
    height="100px"
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    version="1.1"
    viewBox="-20 -50 900 300"
  >
    <g id="logo">
      <g>
        <path
          className="cls-3"
          fill="#e21f26"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="7px"
          d="M113.7,133.5c-9.9,26.9-41.9,39.9-71.5,29.1C12.6,151.7-3.4,121.1,6.5,94.2c9.9-26.9,41.9-39.9,71.5-29.1,29.6,10.9,45.6,41.5,35.7,68.4"
        />
        <path
          className="cls-1"
          strokeWidth="0px"
          d="M19.2,100.8c-8.9,12.8,0,32.2,7.7,39.6,6.8,6.4,21.9,14.6,35.6,11.9-17.4-8.3-41.4-22.7-42.7-51.3"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M92.9,121.8c-2,3.4-7.2,4.1-11.5,1.5s-6.2-7.4-4.2-10.8,7.2-4.1,11.5-1.5,6.2,7.4,4.2,10.8"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M106.5,1.8c-.2-.4-.4-.8-.6-1.2C105.7.2,105.4,0,105,0s-.7.3-1.1.6-.8.7-1.3.7-.5-.1-.8-.3c-.2-.4-.5-.5-.8-.5-.5,0-.9,0-2.5,3.6-3,3.9-6.3,7.6-9.2,11.6,0,0,0,.2-.2.3-2.9,3.9-5.6,8-8.2,12.2s-5,8.6-7.2,13c-2.2,4.4-4.1,8.9-5.8,13.6-.9,2.4-1.5,4.9-2.1,7.5-.5,2.5-.8,5-1.3,7.5-.2,1.1-.4,2.2-.6,3.4,0,.9-.2,1.9-.3,2.8-.2,1.9-.4,3.9-.2,5.7.2,1.6.2,2.8.2,3.8-3.7-2.2-4.4-4.5-5-6.8-.5-1.9-1.1-4.6-3-4.1s-3,2.4-2.5,4.2c1.2,4.7,5,9.4,11.1,11.4.8.3,1.7.5,2.6.7,5.2,1.2,11.7,0,15.7-3,1.5-1.2,1.8-3.4.6-4.9-1.2-1.5-4.9,3-9.4,3.8-.6,0-1.3.2-2.1.3-.7-4.8-.5-9.7.2-14.5.7-5.1,2.1-10.1,3.7-15,1.6-4.6,3.4-9.2,5.5-13.6.7-1.6,1.6-3.1,2.3-4.7,3.5-6.7,7.6-13.2,12.4-19.1,2.2-2.7,6.3-6.6,9.1-8.8l.3-.2c.6-.4,1.4-1.1,3.3-2.7l.2-.2v-.3c.4-1.5-.7-3.7-2-6.3h0Z"
        />
        <path className="cls-1" d="M74.4,61.5" />
      </g>
    </g>
    <g id="tastyfx">
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M197.5,31.9h-19.4c0,24.4-12.8,37.6-35.4,37.6v15.4h17.8v54.8c0,19.6,15.6,25.1,32.8,25.1s18-.7,27.5-3.1v-20.4c-6.1,1.5-10.1,1.8-14.3,1.8-7,0-9-1.7-9-8.4v-49.9h23.7v-16.3h-23.7V31.9h0Z"
      />
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M325,137.2v-43.7c0-24.4-26-28.8-44.8-28.8s-46.2,1.8-46.2,23.8,9.2,15.8,18.7,15.8,17.4-3.9,17.4-13-2.6-8.1-7.2-10.3c1.5-1.5,4.8-4,12.5-4s11.7,2,12.3,10.5v15.2c-9.5,1.1-18.2,3.1-26.6,5.7-16.1,5-32.5,11.7-32.5,30.8s15.2,26,31.2,26,24.4-4.6,31-16.5c2.4,14.9,11.9,16.5,20.7,16.5,19.1,0,23.3-3.5,27.7-5.5v-18.7c-2.8,1.3-5.9,2.4-8.4,2.4-4.2,0-5.9-1.8-5.9-6.2h0ZM287.8,130.9c0,6.4-6.1,12.5-12.7,12.5s-7.9-4.2-7.9-9c0-11.6,11-16.1,20.5-17.6v14.1h.1Z"
      />
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M422.1,104.5l-39.6-9.2c-3.1-.7-5-2.2-5-5.3,0-7.2,8.8-8.6,14.3-8.6,12.5,0,23.7,5,27.9,17.1h16.3v-34.3h-13.6l-4.2,7.2c-10.3-4.8-19.8-7.2-31.4-7.2-21.1,0-42.9,9.4-42.9,33.6s7.5,23.1,22,26l35.8,7.9c4.2.6,6.4,2.6,6.4,6.8,0,7.5-9.7,8.4-15,8.4-12.5,0-26.6-5.7-31-18.9h-16.3v34.8h14.9l3.3-7.3c9.5,6.8,21.5,9.2,33.4,9.2,19.6,0,44.4-10.3,44.4-33.9s-7.2-23.5-19.6-26.2h0Z"
      />
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M842.1,146.1c-5.2,0-7.7-.4-10.5-1.8-5.4-2.8-16.9-14.5-31.2-31.4l19.6-17.5c11.3-9.7,13.5-10.7,22.8-11.3v-15.7h-46v15.3h2.2c2.8,0,4.2.8,4.2,2.2s-1,2.6-2.8,4.6l-10.9,10.9-8.7-10.5c-2.4-2.8-2.8-3.4-2.8-4.6,0-1.8,1.6-2.6,5-2.6h3v-15.3h-62.5v15.7c9.3,1.6,13.3,3.8,18.7,9.9l21,22.4-21.2,19.6c-10.5,9.7-7.9,10.3-17.2,10.3h-1.4v16.7h44.4v-15.9h-2c-4.2,0-6.7-1-6.7-3s1.4-3.2,5-6.7l9.5-8.5,9.7,10.7c1.2,1.4,1.8,2.8,1.8,3.8,0,2.4-3,3.6-8.7,3.6v15.9h68.3v-16.7h-2.8.2Z"
      />
      <g>
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M647.1,68.3h-47.4v16.1h8.1c4.1,0,4.4,1.1,4.4,3.2s-2.4,8-16.7,40.4l-1.9,8.5-1.7-8.7c-12-28-16.3-38-16.3-40.2s1.5-3.2,8.5-3.2h5.2v-16.1h-36.8l-54.5-.2V31.1h-19.6c0,24.6-13,38-35.7,38v15.6h18v55.4c0,19.8,15.7,25.4,33.2,25.4s18.2-.7,27.8-3.1v-20.6c-6.1,1.5-10.2,1.9-14.4,1.9-7,0-9.1-1.7-9.1-8.5v-50.4h33.3c2.9,0,4.8,1.8,6.1,4.4,36.9,85.7,37,85.9,37,86.1-4.3,13.7-10.7,20.7-20,20.7s-3.9-.7-4.6-1.5c5-2.6,7.6-5.7,7.6-11.5,0-9.3-6.9-14.3-15.7-14.3s-19.6,6.5-19.6,18.9,15.4,23.5,30,23.5,29.5-7,35.7-21.5c39.3-90.4,42.2-103.5,47.9-105h11.3v-16.3h0Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M706.2,24.8c-13.7,0-26.2,4.8-32.7,12.9-4.4,5.6-5.8,11.3-5.8,25.2v5.4h-16.4v16.3h16.4v52.6c0,7.9-1.4,9.3-9.3,9.5h-6v16.1h65.7v-16.1h-8.1c-7.7-.2-8.7-1.2-8.9-9.5v-52.6h16.9v-16.3h-16.9v-12.7c0-6.9.2-9.1,1-11.3,1.2-3.4,5.2-6,9.3-6s4,.4,6.7,1c-5.4,3-7.3,5.6-7.3,10.7s6,12.1,13.7,12.1,14.3-6.2,14.3-14.9c0-13.3-13.1-22.4-32.7-22.4h0Z"
        />
      </g>
    </g>
    <g id="tagline">
      <g>
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M474.2,243.5h5v4.3c.8-1.4,2.1-2.5,3.9-3.4s3.7-1.4,5.8-1.4,5.5.7,7.6,2,3.7,3.1,4.8,5.4c1.1,2.3,1.7,4.9,1.7,7.9s-.6,5.6-1.7,7.9-2.8,4.1-4.9,5.4-4.7,2-7.7,2-3.7-.4-5.5-1.3-3.2-1.9-4.2-3.3v14.8h-5v-40.3h.2ZM495.4,266.2c1.8-2,2.6-4.6,2.6-7.8s-.9-5.9-2.6-7.8-4.1-3-7-3-5.2,1-7,3c-1.7,2-2.6,4.6-2.6,7.8s.9,5.9,2.6,7.8c1.7,2,4.1,3,7,3s5.3-1,7-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M513.8,271.6c-2.3-1.3-4.1-3.1-5.3-5.4s-1.9-4.9-1.9-7.9.6-5.6,1.9-7.9c1.3-2.3,3.1-4.1,5.3-5.4,2.3-1.3,4.9-2,7.8-2s5.5.7,7.8,2,4.1,3.1,5.4,5.4c1.3,2.3,1.9,4.9,1.9,7.9s-.6,5.6-1.9,7.9c-1.3,2.3-3.1,4.1-5.4,5.4-2.3,1.3-4.9,2-7.8,2s-5.5-.7-7.8-2ZM528.8,266.1c1.8-2,2.8-4.6,2.8-7.8s-.9-5.8-2.8-7.8c-1.8-2-4.2-3-7.2-3s-5.3,1-7.2,3c-1.8,2-2.8,4.6-2.8,7.8s.9,5.8,2.8,7.8c1.8,2,4.2,3,7.2,3s5.3-1,7.2-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M538,243.5h5.1l6.4,22.6,6.9-22.6h4.8l6.9,22.6,6.3-22.6h5.2l-9.1,29.6h-4.9l-6.9-22.5-6.9,22.5h-4.9l-9-29.6h.1Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M609.4,259.2h-23.4c.2,3,1.1,5.4,2.8,7.2,1.7,1.8,3.9,2.7,6.6,2.7s4.2-.5,5.6-1.5c1.5-1,2.4-2.4,2.9-4.3l4.7,1.1c-.7,2.9-2.3,5.1-4.6,6.7s-5.2,2.4-8.6,2.4-5.4-.7-7.6-2c-2.2-1.4-3.9-3.2-5.1-5.5-1.2-2.3-1.8-4.9-1.8-7.7s.6-5.4,1.7-7.7c1.2-2.3,2.8-4.2,5-5.5,2.2-1.4,4.7-2,7.6-2s5.3.6,7.5,1.9c2.2,1.2,3.8,2.9,5,5.1,1.2,2.1,1.7,4.5,1.7,7s0,1.6,0,2.3v-.2ZM604.2,255.1c-.2-2.3-1.1-4.2-2.7-5.7-1.6-1.4-3.6-2.2-6.2-2.2s-4.3.7-5.9,2.1c-1.6,1.4-2.7,3.3-3.2,5.8h18Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M628,247.7c-2.5,0-4.6,1.2-6.1,3.6-1.6,2.4-2.4,5.9-2.4,10.5v11.3h-5v-29.6h5v6c.9-1.9,2.2-3.4,3.9-4.6s3.5-1.8,5.3-1.8,2.4.2,3.4.5l-.3,4.8c-1.1-.5-2.4-.7-3.8-.7h0Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M661.5,259.2h-23.4c.2,3,1.1,5.4,2.8,7.2,1.7,1.8,3.9,2.7,6.6,2.7s4.2-.5,5.6-1.5c1.5-1,2.4-2.4,2.9-4.3l4.7,1.1c-.7,2.9-2.3,5.1-4.6,6.7s-5.2,2.4-8.6,2.4-5.4-.7-7.6-2c-2.2-1.4-3.9-3.2-5.1-5.5-1.2-2.3-1.8-4.9-1.8-7.7s.6-5.4,1.7-7.7c1.2-2.3,2.8-4.2,5-5.5,2.2-1.4,4.7-2,7.6-2s5.3.6,7.5,1.9c2.2,1.2,3.8,2.9,5,5.1,1.2,2.1,1.7,4.5,1.7,7s0,1.6,0,2.3v-.2ZM656.3,255.1c-.2-2.3-1.1-4.2-2.7-5.7-1.6-1.4-3.6-2.2-6.2-2.2s-4.3.7-5.9,2.1c-1.6,1.4-2.7,3.3-3.2,5.8h18Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M671.6,271.6c-2.2-1.3-3.8-3.1-4.9-5.4s-1.7-4.9-1.7-7.9.6-5.6,1.7-7.9c1.1-2.3,2.7-4.1,4.9-5.4,2.1-1.3,4.7-2,7.7-2s3.8.4,5.6,1.3c1.8.9,3.1,2,4.1,3.4v-16.2h5v41.6h-5v-4.3c-.7,1.4-1.9,2.5-3.7,3.4s-3.8,1.4-5.9,1.4-5.5-.7-7.7-2h0ZM686.8,266.2c1.8-2,2.6-4.6,2.6-7.8s-.9-5.9-2.6-7.8-4.1-3-7-3-5.2,1-7,3-2.6,4.6-2.6,7.8.9,5.9,2.6,7.8,4.1,3,7,3,5.2-1,7-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M723.7,272.2c-1.8-.9-3-2-3.7-3.4v4.3h-5v-41.6h5v16.2c.9-1.4,2.3-2.5,4.1-3.4s3.7-1.3,5.6-1.3c3,0,5.5.7,7.7,2,2.1,1.3,3.8,3.1,4.9,5.4,1.1,2.3,1.7,4.9,1.7,7.9s-.6,5.6-1.7,7.9-2.8,4.1-4.9,5.4c-2.2,1.3-4.7,2-7.7,2s-4.1-.5-5.9-1.4h0ZM736.2,266.2c1.8-2,2.6-4.6,2.6-7.8s-.9-5.9-2.6-7.8-4.1-3-7-3-5.2,1-7,3c-1.7,2-2.6,4.6-2.6,7.8s.9,5.9,2.6,7.8c1.7,2,4.1,3,7,3s5.2-1,7-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M772.3,243.5l-15.5,40.3h-5.1l4.3-11.1-11.6-29.2h5.4l8.7,22.7,8.7-22.7h5.1,0Z"
        />
      </g>
      <g id="IG_black" data-name="IG black">
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M804.1,232.9v40.1h-7.7v-40.1h7.7ZM829,231.9h-.2c-5.4,0-10.5,2.2-14.4,5.9-3.9,3.8-6.2,8.8-6.4,14.2-.2,5.8,1.8,11.3,5.8,15.5s9.4,6.5,15.2,6.5,12-2.4,16-6.3v-17.8h-16.7v7.4h9.2v6.5h0c-2.3,1.5-5.3,2.5-8.3,2.5s-7.2-1.5-9.8-4.2-3.9-6.3-3.7-10c.4-6.8,5.9-12.3,12.7-12.7,3.8-.2,7.3,1.3,10.2,4.1,0,0,.2.2.3.4l5.4-5.4c0-.1-.2-.3-.3-.4-3.8-4-9-6.3-14.8-6.3h-.2Z"
        />
      </g>
    </g>
  </svg>
);

export const TastyLogoLarge = (): ReactElement => (
  <svg
    width="300px"
    height="150px"
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    version="1.1"
    viewBox="-20 0 900 300"
  >
    <g id="logo">
      <g>
        <path
          className="cls-3"
          fill="#e21f26"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="7px"
          d="M113.7,133.5c-9.9,26.9-41.9,39.9-71.5,29.1C12.6,151.7-3.4,121.1,6.5,94.2c9.9-26.9,41.9-39.9,71.5-29.1,29.6,10.9,45.6,41.5,35.7,68.4"
        />
        <path
          className="cls-1"
          strokeWidth="0px"
          d="M19.2,100.8c-8.9,12.8,0,32.2,7.7,39.6,6.8,6.4,21.9,14.6,35.6,11.9-17.4-8.3-41.4-22.7-42.7-51.3"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M92.9,121.8c-2,3.4-7.2,4.1-11.5,1.5s-6.2-7.4-4.2-10.8,7.2-4.1,11.5-1.5,6.2,7.4,4.2,10.8"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M106.5,1.8c-.2-.4-.4-.8-.6-1.2C105.7.2,105.4,0,105,0s-.7.3-1.1.6-.8.7-1.3.7-.5-.1-.8-.3c-.2-.4-.5-.5-.8-.5-.5,0-.9,0-2.5,3.6-3,3.9-6.3,7.6-9.2,11.6,0,0,0,.2-.2.3-2.9,3.9-5.6,8-8.2,12.2s-5,8.6-7.2,13c-2.2,4.4-4.1,8.9-5.8,13.6-.9,2.4-1.5,4.9-2.1,7.5-.5,2.5-.8,5-1.3,7.5-.2,1.1-.4,2.2-.6,3.4,0,.9-.2,1.9-.3,2.8-.2,1.9-.4,3.9-.2,5.7.2,1.6.2,2.8.2,3.8-3.7-2.2-4.4-4.5-5-6.8-.5-1.9-1.1-4.6-3-4.1s-3,2.4-2.5,4.2c1.2,4.7,5,9.4,11.1,11.4.8.3,1.7.5,2.6.7,5.2,1.2,11.7,0,15.7-3,1.5-1.2,1.8-3.4.6-4.9-1.2-1.5-4.9,3-9.4,3.8-.6,0-1.3.2-2.1.3-.7-4.8-.5-9.7.2-14.5.7-5.1,2.1-10.1,3.7-15,1.6-4.6,3.4-9.2,5.5-13.6.7-1.6,1.6-3.1,2.3-4.7,3.5-6.7,7.6-13.2,12.4-19.1,2.2-2.7,6.3-6.6,9.1-8.8l.3-.2c.6-.4,1.4-1.1,3.3-2.7l.2-.2v-.3c.4-1.5-.7-3.7-2-6.3h0Z"
        />
        <path className="cls-1" d="M74.4,61.5" />
      </g>
    </g>
    <g id="tastyfx">
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M197.5,31.9h-19.4c0,24.4-12.8,37.6-35.4,37.6v15.4h17.8v54.8c0,19.6,15.6,25.1,32.8,25.1s18-.7,27.5-3.1v-20.4c-6.1,1.5-10.1,1.8-14.3,1.8-7,0-9-1.7-9-8.4v-49.9h23.7v-16.3h-23.7V31.9h0Z"
      />
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M325,137.2v-43.7c0-24.4-26-28.8-44.8-28.8s-46.2,1.8-46.2,23.8,9.2,15.8,18.7,15.8,17.4-3.9,17.4-13-2.6-8.1-7.2-10.3c1.5-1.5,4.8-4,12.5-4s11.7,2,12.3,10.5v15.2c-9.5,1.1-18.2,3.1-26.6,5.7-16.1,5-32.5,11.7-32.5,30.8s15.2,26,31.2,26,24.4-4.6,31-16.5c2.4,14.9,11.9,16.5,20.7,16.5,19.1,0,23.3-3.5,27.7-5.5v-18.7c-2.8,1.3-5.9,2.4-8.4,2.4-4.2,0-5.9-1.8-5.9-6.2h0ZM287.8,130.9c0,6.4-6.1,12.5-12.7,12.5s-7.9-4.2-7.9-9c0-11.6,11-16.1,20.5-17.6v14.1h.1Z"
      />
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M422.1,104.5l-39.6-9.2c-3.1-.7-5-2.2-5-5.3,0-7.2,8.8-8.6,14.3-8.6,12.5,0,23.7,5,27.9,17.1h16.3v-34.3h-13.6l-4.2,7.2c-10.3-4.8-19.8-7.2-31.4-7.2-21.1,0-42.9,9.4-42.9,33.6s7.5,23.1,22,26l35.8,7.9c4.2.6,6.4,2.6,6.4,6.8,0,7.5-9.7,8.4-15,8.4-12.5,0-26.6-5.7-31-18.9h-16.3v34.8h14.9l3.3-7.3c9.5,6.8,21.5,9.2,33.4,9.2,19.6,0,44.4-10.3,44.4-33.9s-7.2-23.5-19.6-26.2h0Z"
      />
      <path
        className="cls-2"
        fill="#fff"
        strokeWidth="0px"
        d="M842.1,146.1c-5.2,0-7.7-.4-10.5-1.8-5.4-2.8-16.9-14.5-31.2-31.4l19.6-17.5c11.3-9.7,13.5-10.7,22.8-11.3v-15.7h-46v15.3h2.2c2.8,0,4.2.8,4.2,2.2s-1,2.6-2.8,4.6l-10.9,10.9-8.7-10.5c-2.4-2.8-2.8-3.4-2.8-4.6,0-1.8,1.6-2.6,5-2.6h3v-15.3h-62.5v15.7c9.3,1.6,13.3,3.8,18.7,9.9l21,22.4-21.2,19.6c-10.5,9.7-7.9,10.3-17.2,10.3h-1.4v16.7h44.4v-15.9h-2c-4.2,0-6.7-1-6.7-3s1.4-3.2,5-6.7l9.5-8.5,9.7,10.7c1.2,1.4,1.8,2.8,1.8,3.8,0,2.4-3,3.6-8.7,3.6v15.9h68.3v-16.7h-2.8.2Z"
      />
      <g>
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M647.1,68.3h-47.4v16.1h8.1c4.1,0,4.4,1.1,4.4,3.2s-2.4,8-16.7,40.4l-1.9,8.5-1.7-8.7c-12-28-16.3-38-16.3-40.2s1.5-3.2,8.5-3.2h5.2v-16.1h-36.8l-54.5-.2V31.1h-19.6c0,24.6-13,38-35.7,38v15.6h18v55.4c0,19.8,15.7,25.4,33.2,25.4s18.2-.7,27.8-3.1v-20.6c-6.1,1.5-10.2,1.9-14.4,1.9-7,0-9.1-1.7-9.1-8.5v-50.4h33.3c2.9,0,4.8,1.8,6.1,4.4,36.9,85.7,37,85.9,37,86.1-4.3,13.7-10.7,20.7-20,20.7s-3.9-.7-4.6-1.5c5-2.6,7.6-5.7,7.6-11.5,0-9.3-6.9-14.3-15.7-14.3s-19.6,6.5-19.6,18.9,15.4,23.5,30,23.5,29.5-7,35.7-21.5c39.3-90.4,42.2-103.5,47.9-105h11.3v-16.3h0Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M706.2,24.8c-13.7,0-26.2,4.8-32.7,12.9-4.4,5.6-5.8,11.3-5.8,25.2v5.4h-16.4v16.3h16.4v52.6c0,7.9-1.4,9.3-9.3,9.5h-6v16.1h65.7v-16.1h-8.1c-7.7-.2-8.7-1.2-8.9-9.5v-52.6h16.9v-16.3h-16.9v-12.7c0-6.9.2-9.1,1-11.3,1.2-3.4,5.2-6,9.3-6s4,.4,6.7,1c-5.4,3-7.3,5.6-7.3,10.7s6,12.1,13.7,12.1,14.3-6.2,14.3-14.9c0-13.3-13.1-22.4-32.7-22.4h0Z"
        />
      </g>
    </g>
    <g id="tagline">
      <g>
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M474.2,243.5h5v4.3c.8-1.4,2.1-2.5,3.9-3.4s3.7-1.4,5.8-1.4,5.5.7,7.6,2,3.7,3.1,4.8,5.4c1.1,2.3,1.7,4.9,1.7,7.9s-.6,5.6-1.7,7.9-2.8,4.1-4.9,5.4-4.7,2-7.7,2-3.7-.4-5.5-1.3-3.2-1.9-4.2-3.3v14.8h-5v-40.3h.2ZM495.4,266.2c1.8-2,2.6-4.6,2.6-7.8s-.9-5.9-2.6-7.8-4.1-3-7-3-5.2,1-7,3c-1.7,2-2.6,4.6-2.6,7.8s.9,5.9,2.6,7.8c1.7,2,4.1,3,7,3s5.3-1,7-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M513.8,271.6c-2.3-1.3-4.1-3.1-5.3-5.4s-1.9-4.9-1.9-7.9.6-5.6,1.9-7.9c1.3-2.3,3.1-4.1,5.3-5.4,2.3-1.3,4.9-2,7.8-2s5.5.7,7.8,2,4.1,3.1,5.4,5.4c1.3,2.3,1.9,4.9,1.9,7.9s-.6,5.6-1.9,7.9c-1.3,2.3-3.1,4.1-5.4,5.4-2.3,1.3-4.9,2-7.8,2s-5.5-.7-7.8-2ZM528.8,266.1c1.8-2,2.8-4.6,2.8-7.8s-.9-5.8-2.8-7.8c-1.8-2-4.2-3-7.2-3s-5.3,1-7.2,3c-1.8,2-2.8,4.6-2.8,7.8s.9,5.8,2.8,7.8c1.8,2,4.2,3,7.2,3s5.3-1,7.2-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M538,243.5h5.1l6.4,22.6,6.9-22.6h4.8l6.9,22.6,6.3-22.6h5.2l-9.1,29.6h-4.9l-6.9-22.5-6.9,22.5h-4.9l-9-29.6h.1Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M609.4,259.2h-23.4c.2,3,1.1,5.4,2.8,7.2,1.7,1.8,3.9,2.7,6.6,2.7s4.2-.5,5.6-1.5c1.5-1,2.4-2.4,2.9-4.3l4.7,1.1c-.7,2.9-2.3,5.1-4.6,6.7s-5.2,2.4-8.6,2.4-5.4-.7-7.6-2c-2.2-1.4-3.9-3.2-5.1-5.5-1.2-2.3-1.8-4.9-1.8-7.7s.6-5.4,1.7-7.7c1.2-2.3,2.8-4.2,5-5.5,2.2-1.4,4.7-2,7.6-2s5.3.6,7.5,1.9c2.2,1.2,3.8,2.9,5,5.1,1.2,2.1,1.7,4.5,1.7,7s0,1.6,0,2.3v-.2ZM604.2,255.1c-.2-2.3-1.1-4.2-2.7-5.7-1.6-1.4-3.6-2.2-6.2-2.2s-4.3.7-5.9,2.1c-1.6,1.4-2.7,3.3-3.2,5.8h18Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M628,247.7c-2.5,0-4.6,1.2-6.1,3.6-1.6,2.4-2.4,5.9-2.4,10.5v11.3h-5v-29.6h5v6c.9-1.9,2.2-3.4,3.9-4.6s3.5-1.8,5.3-1.8,2.4.2,3.4.5l-.3,4.8c-1.1-.5-2.4-.7-3.8-.7h0Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M661.5,259.2h-23.4c.2,3,1.1,5.4,2.8,7.2,1.7,1.8,3.9,2.7,6.6,2.7s4.2-.5,5.6-1.5c1.5-1,2.4-2.4,2.9-4.3l4.7,1.1c-.7,2.9-2.3,5.1-4.6,6.7s-5.2,2.4-8.6,2.4-5.4-.7-7.6-2c-2.2-1.4-3.9-3.2-5.1-5.5-1.2-2.3-1.8-4.9-1.8-7.7s.6-5.4,1.7-7.7c1.2-2.3,2.8-4.2,5-5.5,2.2-1.4,4.7-2,7.6-2s5.3.6,7.5,1.9c2.2,1.2,3.8,2.9,5,5.1,1.2,2.1,1.7,4.5,1.7,7s0,1.6,0,2.3v-.2ZM656.3,255.1c-.2-2.3-1.1-4.2-2.7-5.7-1.6-1.4-3.6-2.2-6.2-2.2s-4.3.7-5.9,2.1c-1.6,1.4-2.7,3.3-3.2,5.8h18Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M671.6,271.6c-2.2-1.3-3.8-3.1-4.9-5.4s-1.7-4.9-1.7-7.9.6-5.6,1.7-7.9c1.1-2.3,2.7-4.1,4.9-5.4,2.1-1.3,4.7-2,7.7-2s3.8.4,5.6,1.3c1.8.9,3.1,2,4.1,3.4v-16.2h5v41.6h-5v-4.3c-.7,1.4-1.9,2.5-3.7,3.4s-3.8,1.4-5.9,1.4-5.5-.7-7.7-2h0ZM686.8,266.2c1.8-2,2.6-4.6,2.6-7.8s-.9-5.9-2.6-7.8-4.1-3-7-3-5.2,1-7,3-2.6,4.6-2.6,7.8.9,5.9,2.6,7.8,4.1,3,7,3,5.2-1,7-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M723.7,272.2c-1.8-.9-3-2-3.7-3.4v4.3h-5v-41.6h5v16.2c.9-1.4,2.3-2.5,4.1-3.4s3.7-1.3,5.6-1.3c3,0,5.5.7,7.7,2,2.1,1.3,3.8,3.1,4.9,5.4,1.1,2.3,1.7,4.9,1.7,7.9s-.6,5.6-1.7,7.9-2.8,4.1-4.9,5.4c-2.2,1.3-4.7,2-7.7,2s-4.1-.5-5.9-1.4h0ZM736.2,266.2c1.8-2,2.6-4.6,2.6-7.8s-.9-5.9-2.6-7.8-4.1-3-7-3-5.2,1-7,3c-1.7,2-2.6,4.6-2.6,7.8s.9,5.9,2.6,7.8c1.7,2,4.1,3,7,3s5.2-1,7-3Z"
        />
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M772.3,243.5l-15.5,40.3h-5.1l4.3-11.1-11.6-29.2h5.4l8.7,22.7,8.7-22.7h5.1,0Z"
        />
      </g>
      <g id="IG_black" data-name="IG black">
        <path
          className="cls-2"
          fill="#fff"
          strokeWidth="0px"
          d="M804.1,232.9v40.1h-7.7v-40.1h7.7ZM829,231.9h-.2c-5.4,0-10.5,2.2-14.4,5.9-3.9,3.8-6.2,8.8-6.4,14.2-.2,5.8,1.8,11.3,5.8,15.5s9.4,6.5,15.2,6.5,12-2.4,16-6.3v-17.8h-16.7v7.4h9.2v6.5h0c-2.3,1.5-5.3,2.5-8.3,2.5s-7.2-1.5-9.8-4.2-3.9-6.3-3.7-10c.4-6.8,5.9-12.3,12.7-12.7,3.8-.2,7.3,1.3,10.2,4.1,0,0,.2.2.3.4l5.4-5.4c0-.1-.2-.3-.3-.4-3.8-4-9-6.3-14.8-6.3h-.2Z"
        />
      </g>
    </g>
  </svg>
);
