type DefaultEvent = {
  event?: string;
  event_category: string | undefined;
  event_action: string | undefined;
  event_label: string | undefined;
  event_value: string | undefined;
  interaction_type: string | undefined;
  interaction_value: string | undefined;
  error: string | undefined;
};

export function initialiseGoogleAnalytics() {
  window.dataLayer = window.dataLayer || [];
  let envSpecificParams = "";

  if (window.location.hostname === "localhost") {
    return;
  }

  if (window.location.hostname.indexOf("net") !== -1) {
    envSpecificParams =
      "&gtm_auth=p3ZMh6aNyvERTSI5DJrlFg&gtm_preview=env-881&gtm_cookies_win=x";
  } else if (window.location.hostname.indexOf("web") !== -1) {
    envSpecificParams =
      "&gtm_auth=mwHUn-1ykIQbkYDANPMfXA&gtm_preview=env-882&gtm_cookies_win=x";
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s) as HTMLScriptElement;
    const dl = l !== "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}${envSpecificParams}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-M29XPPM");
}

const defaultEvent: DefaultEvent = {
  event: undefined,
  event_category: undefined,
  event_action: undefined,
  event_label: undefined,
  event_value: undefined,
  interaction_type: undefined,
  interaction_value: undefined,
  error: undefined,
};

export type LoginMethod = "CREDENTIALS" | "OTP" | "EMAIL" | "SMS";
const loginMethodToEventAction: Record<LoginMethod, string> = {
  CREDENTIALS: "login",
  EMAIL: "login with OTP email",
  SMS: "login with OTP SMS",
  OTP: "login with OTP",
};

type LoginEventLabel =
  | "remember code enable"
  | "remember username and password enable";

export function trackLogin(method: LoginMethod, eventLabel?: LoginEventLabel) {
  window.dataLayer.push({
    ...defaultEvent,
    event: "login",
    event_category: "IG Account",
    event_action: loginMethodToEventAction[method],
    // TODO: We do not have remember code or user/pass fields
    event_label: eventLabel,
    tracking_id: undefined,
    user_status: "logged in",
  });
}

export function trackLoginError() {
  window.dataLayer.push({
    ...defaultEvent,
    event: "login_error",
    event_category: "IG Account",
    event_action: "Login error",
    interaction_type: "Login error",
    error: "mfa failure",
  });
}

export function trackOtpEnterCodeView() {
  window.dataLayer.push({
    ...defaultEvent,
    event: "screen_view",
    screenName: "OtpActivity",
    screenClass: "One time password",
    page_type: undefined,
    page_category: undefined,
  });
}

export function trackContinueWithAuthenticationAppClick() {
  window.dataLayer.push({
    ...defaultEvent,
    event: "security_interaction",
    event_category: "Security interaction",
    event_action: "Select 2FA another method",
    event_label: "Click Continue with Authentication App",
    interaction_type: "Select 2FA another method",
    interaction_value: "Click Continue with Authentication App",
  });
}

export function trackContinueWithSMSClick() {
  window.dataLayer.push({
    ...defaultEvent,
    event: "security_interaction",
    event_category: "Security interaction",
    event_action: "Select 2FA another method",
    event_label: "Click continue with SMS",
    interaction_type: "Select 2FA another method",
    interaction_value: "Click continue with SMS",
  });
}

export function trackContinueWithEmailClick() {
  window.dataLayer.push({
    ...defaultEvent,
    event: "security_interaction",
    event_category: "Security interaction",
    event_action: "Select 2FA another method",
    event_label: "Click Continue with Email",
    interaction_type: "Select 2FA another method",
    interaction_value: "Click Continue with Email",
  });
}
export function trackNeedHelpWithThisClick() {
  window.dataLayer.push({
    ...defaultEvent,
    event: "security_interaction",
    event_category: "Security interaction",
    event_action: "Select 2FA another method",
    event_label: "Click Need help with this",
    interaction_type: "Select 2FA another method",
    interaction_value: "Click Need help with this",
  });
}
